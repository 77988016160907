import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/assets/images/Color-logo-no-background.svg" className="App-logo" alt="logo" />
        <p>
          Website is under construction.
        </p> 
      </header>
    </div>
  );
}

export default App;
